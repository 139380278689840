<template>
  <div class="faq_list">
    <div class="container">
      <h3 class="title">Frequently Asked Questions</h3>
      <el-collapse v-model="activeNames" @change="handleChange">
        <el-collapse-item v-for="(item, index) in faqList" :id="item.id" :key="index" :title="item.title" :name="item.name">
          <div v-html="item.dec" />
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeNames: ['1'],
      faqList: [
        {
          name: '1',
          id: 'question-1',
          title: '1. ¿QUÉ ES?',
          dec: 'Aquí hay un enfoque simple e ideal para las finanzas personales que puede ayudar a cubrir los gastos de subsistencia inmediatos:<br>Pagar multas o facturas inesperadas Pague veterinario para sus mascotas o facturas médicas, comprar regalos de cumpleaños sorpresa. Paga cursos para seguir mejorando tus habilidades'
        },
        {
          name: '2',
          id: 'question-2',
          title: '2. ¿CUÁL ES LA FORMA DE REEMBOLSAR EL PRÉSTAMO?',
          dec: 'Pague en línea a través de nuestras de nuestras plataformas, seleccione pagar y realice pagos virtuales'
        },
        {
          name: '3',
          id: 'question-3',
          title: '3. ¿CÓMO PUEDO VER LAS POLÍTICAS DE PRIVACIDAD?',
          dec: 'Haga clic para ver nuestra política de privacidad.'
        },
        {
          name: '4',
          id: 'question-4',
          title: '4. ¿QUÉ DEBO HACER SI SE RECHAZA LA SOLICITUD?',
          dec: 'Nuestro sistema totalmente automatizado tiene en cuenta varios factores de análisis de riesgo. Si lamentablemente su solicitud es rechazada, puede intentar solicitar un préstamo dentro de los 30 días y haremos todo lo posible para ayudarlo.'
        },
        {
          name: '5',
          id: 'question-5',
          title: '5. ¿PUEDO SOLICITAR  UNA PRÓRROGA DEL PRÉSTAMO?',
          dec: 'Sí, si el monto total del préstamo no se puede pagar en la fecha, es posible solicitar este período con la ayuda de un asesor de servicio y cobraremos intereses de acuerdo con la ley colombiana. Sin embargo, si no paga activamente, buscaremos la liquidación al más alto nivel legal y nos aseguraremos de que su incumplimiento de contrato sea tratado por abogados profesionales.'
        },
        {
          name: '6',
          id: 'question-6',
          title: '6. ¿DÓNDE PUEDO VER EL PRÉSTAMO?',
          dec: 'Acreditaremos su préstamo lo antes posible a la tarjeta bancaria que dejó cuando solicitó el préstamo y el dinero se acreditará a su tarjeta bancaria para su uso.'
        },
        {
          name: '7',
          id: 'question-7',
          title: '7. LUEGO DE HABER REEMBOLSADO EL PRÉSTAMO. ¿CUÁNDO PUEDO OBTENER OTRO PRÉSTAMO?',
          dec: 'Usted puede volver a solicitar el préstamo el día después de pagar y mantener su crédito. Le daremos un límite de préstamo más alto para que pueda manejar gastos repentinos difíciles.'
        },
        {
          name: '8',
          id: 'question-8',
          title: '8. SI TENGO ALGUNA PREGUNTA, ¿CÓMO PUEDO CONTACTAR CON USTEDES?',
          dec: 'No se preocupe, si puede ponerse en contacto con un asesor de servicio profesional para el problema del reembolso, el asesor de servicio se comunicará con usted después de recibir la tarea.'
        },
        {
          name: '9',
          id: 'question-9',
          title: '9. ¿QUÉ INFORMACIÓN SE REQUIERE PARA SOLICITAR UN PRÉSTAMO?',
          dec: 'Ser ciudadano colombiano<br>Ser mayor de edad y vivir en Colombia<br>Tener una cuenta bancaria legal en Colombia<br>Tu correo electrónico'
        },
        {
          name: '10',
          id: 'question-10',
          title: '10. ¿EN CUÁNTO TIEMPO PUEDO OBTENER EL DINERO DESPUÉS DE SOLICITAR UN PRÉSTAMO?',
          dec: 'Su límite de crédito será depositado en su cuenta bancaria en menos de 24 horas hábiles y en un máximo de 5 minutos.'
        },
        {
          name: '11',
          id: 'question-11',
          title: '11. ¿PUEDO SABER EL CONTRATO DE PRÉSTAMO POR ADELANTADO?',
          dec: 'Sí, haga clic aquí para ver <a href="/Contrato de préstamos.pdf">《Términos y Condiciones》</a>'
        },
        {
          name: '12',
          id: 'question-12',
          title: '12. ¿CÓMO PUEDO AUMENTAR MI CRÉDITO?',
          dec: 'Una línea de crédito se genera automáticamente en el sistema para garantizar un pago bueno y oportuno para que pueda obtener más dinero la próxima vez que pida dinero prestado.'
        }
      ]
    }
  },
  methods: {
    handleChange(val) {
      console.log(val)
    }
  }

}
</script>

<style lang="scss" scoped>
.faq_list{
  padding: 50px 0;
  width: 1200px;
  margin: 0 auto;
  .title{
    font-size: 30px;
    color: #333333;
    margin-bottom: 40px;
  }
}
</style>
